<template>
  <v-dialog v-model="show" scrollable persistent max-width="1800">
    <v-card tile>
      <v-toolbar dark dense>
        <v-toolbar-title> AYUDA </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon dark @click.stop="show = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text v-if="$props.faqs && $props.faqs.length > 0">
        <v-row>
          <v-col cols="12" />
          <v-col cols="12">
            <v-text-field
              v-model="tbl_search"
              append-icon="mdi-magnify"
              label="Buscar..."
              single-line
              hide-details
              dense
            />
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="tbl_headers"
              :search="tbl_search"
              :items="$props.faqs"
              dense
            >
              <template v-slot:item.key="{ item }">
                <b v-text="item.key" />
              </template>
              <template v-slot:item.url_video="{ item }">
                <v-btn
                  v-if="item.url_video"
                  icon
                  small
                  color="success"
                  :href="item.url_video"
                  target="_blank"
                >
                  <v-icon> mdi-video-check</v-icon>
                </v-btn>
                <v-icon small v-else> mdi-video-off </v-icon>
              </template>
              <template v-slot:item.faq_documents="{ item }">
                <div v-for="(faq_document, i) in item.faq_documents" :key="i">
                  <a
                    class="text-caption no-decoration"
                    target="_blank"
                    :href="url_document + '/faqs/' + faq_document.url"
                  >
                    {{ i + 1 }}. {{ faq_document.description }}
                  </a>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { URL_DOCUMENTS } from "../control";

export default {
  data() {
    return {
      url_document: URL_DOCUMENTS,
      tbl_search: "",
      tbl_headers: [
        {
          text: "#",
          filterable: false,
          sortable: false,
          value: "key",
          width: "60px",
        },
        {
          text: "Titulo",
          filterable: true,
          value: "title",
        },
        {
          text: "Video",
          filterable: false,
          sortable: false,
          value: "url_video",
          width: "60px",
        },
        {
          text: "Comentario",
          filterable: true,
          value: "comment",
        },
        {
          text: "Archivos",
          filterable: false,
          sortable: false,
          value: "faq_documents",
          width: "150px",
        },
      ],
    };
  },
  props: {
    value: Boolean,
    faqs: Array,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style scoped>
.no-decoration {
  text-decoration: none;
}
</style>