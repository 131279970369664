<template>
  <div>
    <span class="text-caption font-weight-bold" v-text="$attrs.label" />
    <v-icon v-if="$attrs.alert" small color="orange" v-text="'mdi-alert'" />
    <br />
    <span class="text-description" v-text="$attrs.value !== '' ? $attrs.value : '-'" />
    <span v-if="$attrs.subvalue">
      <br />
      <span class="text-caption" v-text="$attrs.subvalue" />
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style>
.text-description {
  white-space: pre-line;
}
</style>